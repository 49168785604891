<template>
  <div class="main__column">
    <div class="main__column-activity">
      <div class="title">
        {{$t('matrix.activity_tactile_history.title')}}
        <span>{{$t('matrix.activity_tactile_history.allCircles')}}</span>
      </div>
      <div class="column__activity-items" ref="list">
        <div class="column__activity-item" v-for="(transaction, idx) in transactions" :key="idx">
          <div class="column__activity-item_user">
            <div class="user__img">
              <UiAvatar :user="transaction.user" class="user__img" />
            </div>
            <div class="user__id-date">
              <p class="user__id-title">
                {{ transaction.user.name }}
                <span class="user__id-time">{{ transaction.time }}</span>
              </p>
              <span class="user__id-message">{{ transaction.description }}</span>
              <div class="user__tag-wrap">
                <div class="user__tag-text">System</div>
              </div>
            </div>
          </div>
        </div>
        <UiLoader v-if="loading" theme="block" :loading="true" />
        <div class="historyBtn">
          <ButtonDark :text="$t('showMore')" :classes="'historyShowActive'" :disabled="fetchedAllHistory" @event="showMoreHistory"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { isSameAccount, getExplorerLinkByTx } from "@/helpers/user"
// import throttle from "lodash/throttle"
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  components: {
    ButtonDark
  },
  data() {
    return {
      user: '',
      loading: false,
      transactions: [],
      meta: {},
      fetchedAllHistory: false,
    }
  },
  // created() {
  //   this.scrollListWithThrottle = throttle(this.handleListScroll, 300)
  // },

  async mounted() {
    // window.addEventListener("scroll", this.scrollListWithThrottle, false)
    await this.fetchUser()
    this.fetchData()
  },
  computed: {
    ...mapGetters("crypto", ["getAccount"])
  },
  methods: {
    ...mapActions("user", ["getTransactions", "guessUser"]),

    async fetchUser() {
      const accountQuery = this.$route.query.account
      const targetAccount = accountQuery || await this.getAccount
      if (!targetAccount) this.user = null
      let userResponse = await this.guessUser(targetAccount)
      this.user = userResponse
    },
    async fetchData(page) {
      this.loading = true
      const { data, meta } = await this.getTransactions({ account: this.user.account, limit: 15, page })

      this.setTransactions(data)
      this.meta = meta

      this.loading = false
    },
    // async handleListScroll() {
    //   if (!this.$refs.list || this.loading) return
    //   const { current_page, total_pages } = this.meta
    //   if (current_page >= total_pages) return

    //   const { top, height } = this.$refs.list.getBoundingClientRect()
    //   const bottomScroll = top - window.innerHeight
    //   if (bottomScroll > 0) return

    //   const scrollRest = Math.abs(bottomScroll) - height

    //   if (scrollRest >= -300) {
    //     await this.fetchData(current_page + 1)
    //   }
    // },
    setTransactions(transactions) {
      const sliceTMP = (str) => {
        return `...${str.slice(-4)}`
      }

      const newTransactions = transactions.map((tr) => {
        let descriptionByType = ""

        const isMyOutcoming = isSameAccount(tr.from.account, this.user.account)
        // If not outcoming - than incoming by backend tr.to match
        let targetUser = {}

        if (isMyOutcoming) {
          targetUser = {
            isMyAccount: true,
            name: this.$t("activity_history.your_account"),
            avatar: tr.from.avatar,
          }
        } else {
          targetUser = {
            // name: sliceTMP(tr.from),
            name: tr.from.name,
            avatar: tr.from.avatar,
          }
        }

        switch (tr.type) {
          case "registration":
            descriptionByType = this.$t("activity_history.activated_ref")
            break

          case "simpleBuy":
            descriptionByType = isMyOutcoming
              ? this.$t("activity_history.you_activated", { lvl: tr.lvl })
              : this.$t("activity_history.income_ref", { price: tr.price })
            break
          default:
            break
        }

        return {
          out: getExplorerLinkByTx(tr.transaction_hash),
          user: targetUser,
          description: descriptionByType,
          time: this.$moment(tr.created_at).fromNow(),
        }
      })

      this.transactions = [...this.transactions, ...newTransactions]
    },
    async showMoreHistory() {
        const { current_page, total_pages } = this.meta
        if (current_page >= total_pages) {
            this.fetchedAllHistory = true
            return  
        } 
        await this.fetchData(current_page + 1)

    },
  },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.scrollListWithThrottle, false)
  // },
}
</script>
<style lang="scss">
.historyBtn {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.historyShowActive {
    background: var(--color-orange-900);
    padding: var(--indent-10) var(--indent-16);
    border-radius: var(--indent-8);
    font-size: var(--font-12);
    line-height: var(--line-16);
    letter-spacing: 1px;
    font-weight: var(--font-500);
    color: var(--color-white);
    transition: background 500ms;
    &:hover {
        background: var(--color-orange-800);
    }
}
</style>