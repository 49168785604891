<template>
    <div class="pagination">
        <ul class="pagination__list">
            <li class="pagination__prev">
                <BaseIcon name="pagArrowLeft" verAlign @event="handlePrev"/>
            </li>
            <li 
                v-for="(item, idx) in items" :key="idx"
                :class="[item === meta.current && 'pagination__item--active']" 
                class="pagination__item"
                @click.stop="() => typeof item === 'number' && $emit('onChange', item)"
            >
                <p 
                    v-if="typeof item === 'number'" 
                    class="pagination__link" 
                >{{ item }}</p>
                <p v-else>...</p>
            </li>
            <li class="pagination__next">
                <BaseIcon name="pagArrowRight" verAlign @event="handleNext" />
            </li>
        </ul>
        <button @click.stop="switchPagination" class="pagination__buttonClose"><svg-vue icon="close" /></button>
    </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import { paginationBuilder } from "@/helpers/pagination"
import debounce from "lodash/debounce"

export default {
    components: {
        BaseIcon
    },
    data() {
        return {
            wWidth: 0,
        }
    },

    created() {
        this.setWindowDebounce = debounce(this.setWindow, 200)
    },
    mounted() {
        this.setWindow()
        window.addEventListener("resize", this.setWindowDebounce)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.setWindowDebounce)
    },
    props: {
        meta: Object,
    },
    computed: {
        items() {
            const { total, current } = this.meta
            let pages = [5, 7]

            if (this.wWidth <= 625) {
                pages = [3, 5]
            }

            return paginationBuilder(current, total, pages[0], pages[1])
        },
        prevAvailable() {
            return this.meta.current > 1
        },
        nextAvailable() {
            return this.meta.current < this.meta.total
        },
    },
    methods: {
        switchPagination() {
            this.$emit('switchPaginationToShort')
        },
        setWindow() {
            this.wWidth = window.innerWidth
        },
        handlePrev() {
            if (this.prevAvailable) {
                this.$emit("onChange", this.meta.current - 1)
            }
        },
        handleNext() {
            if (this.nextAvailable) {
                this.$emit("onChange", this.meta.current + 1)
            }
        },
    },
}
</script>
<style lang="scss">
.pagination {
  &__buttonClose {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
}
</style>