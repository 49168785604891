<template>
    <section class="level section-offset">
        <header class="level-header">
            <a class="level-back" @click="goBack">
                <svg-vue class="level-svg" icon="arrow-back" />
                <span>{{ $t("matrix.back") }}</span>
            </a>
        </header>
        
        <template v-if="user">
            <MatrixLevelTactile :lvl="lvl" :user="user" />
            <!-- <LevelClassic :lvl="lvl" :user="user" /> -->
        </template>
        <div v-else class="my-5">
            <UiLoader theme="block" :loading="true" />
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
// import LevelClassic from "@/components/Matrix/LevelClassic.vue"
import MatrixLevelTactile from "@/components/Matrix/MatrixLevelTactile.vue"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"

export default {
    props: ["lvl"],
    components: { 
        MatrixLevelTactile,
        // LevelClassic,
    },
    mixins: [pageReadyByConnect],
    data() {
        return {
            user: null
        }
    },
    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "isConnected"])
    },

    methods: {
        async pageReady() {
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t("errors.registrationError"))
                await this.$router.push({ name: "signup" })
                return
            }

            const accountQuery = this.$route.query.account
            const targetAccount = accountQuery || this.getAccount

            try {
                let userResponse = await this.guessUser(targetAccount)
                if (!userResponse) throw new Error()

                const [levelsErr, levels] = await this.getProgramLevels(userResponse.account)

                try {
                    const activeLevel = levels.findLast((x) => x.active).lvl

                    if (Number(this.lvl) - 1 > activeLevel) throw new Error()

                    if (!Number.isFinite(Number(this.lvl))) {
                        await this.$router.push({ name: "classiclevel", params: { lvl: activeLevel + 1 } })
                    }
                } catch (err) {
                    // this.$swal(this.$t("errors.levelNotValid"))
                    // this.$router.push({ name: "dashboard" })
                }

                this.user = userResponse
            } catch (err) {
                this.$swal(this.$t("errors.userNotFound"))
                await this.$router.push({ name: "dashboard" })
            }
        },
        ...mapActions("crypto", ["checkRegistration", "getProgramLevels"]),
        ...mapActions("user", ["guessUser"]),
        goBack() {
            this.$router.go(-1) 
        }
    }
}
</script>
