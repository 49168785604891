<template>
    <div class="classic__level-map" :class="isSideBarClose ? 'circles__close' : 'circles__lg'" v-if="cardType === 's6'">
        <ul class="circle__container circle__4">
            <li>
                <!-- Third line top right -->
                <p
                    :content="tooltipContent('lvl2', 2)"    
                    v-tippy="{ maxWidth: 380 }"
                    @click="clickTooltip(getAddress('lvl2', 2))"
                >
                    <UiAvatar v-if="blockchain?.lvl2[2]" :user="blockchain?.lvl2[2]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                    <!-- old version of empty avatar -->
                    <!-- <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.756836" y="0.517639" width="40.5299" height="40.5299" rx="20.265" fill="#666666" />
                        <g clip-path="url(#clip0_4202_40066)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M25.495 29.8199V31.3918C28.3107 31.8409 30.9012 32.8514 33.0412 34.1987C34.2801 35.0969 35.4065 39.8126 35.7443 41.0476H7.02344C7.36133 39.8126 8.375 35.0968 9.72656 34.1987C11.7539 32.8514 14.457 31.8408 17.2728 31.3918V29.8199C16.1465 28.8093 15.3581 27.2375 15.0202 25.5533C13.6686 25.5533 11.9792 20.164 14.2318 20.3885C13.1055 17.4693 14.2318 13.5397 16.3718 12.1922C14.7949 9.38522 16.0339 11.4062 22.454 10.7326C24.256 10.6203 25.9455 11.7431 26.1707 13.3149C29.3244 13.4272 29.3244 17.9183 28.4233 20.3882C30.7886 20.0514 29.0991 25.6653 27.6349 25.553C27.4097 27.2372 26.6212 28.8091 25.4949 29.8195L25.495 29.8199Z"
                                fill="white" fill-opacity="0.4" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4202_40066">
                                <rect x="1.48047" y="0.517639" width="39.8062" height="40.5299" rx="19.9031" fill="white" />
                            </clipPath>
                        </defs>
                    </svg> -->
                </p>
                <!-- Small purple circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl2[2]" /> -->
            </li>
            <li>
                <!-- Third line bottom right -->
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl2', 0)"
                    @click="clickTooltip(getAddress('lvl2', 0))"
                >
                    <UiAvatar v-if="blockchain?.lvl2[0]" :user="blockchain?.lvl2[0]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>
                <!-- Small purple circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl2[0]" /> -->
            </li>
            <li>
                <!-- Third line bottom left -->
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl2', 1)"
                    @click="clickTooltip(getAddress('lvl2', 1))"
                >
                    <UiAvatar v-if="blockchain?.lvl2[1]" :user="blockchain?.lvl2[1]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>
                <!-- Small purple circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl2[1]" /> -->
            </li>
            <li>
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl2', 3)"
                    @click="clickTooltip(getAddress('lvl2', 3))"
                >
                    <!-- Third line top left -->
                    <UiAvatar v-if="blockchain?.lvl2[3]" :user="blockchain?.lvl2[3]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>
                <!-- Small purple circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl2[3]" /> -->
            </li>
        </ul>
        <ul class="circle__container circle__2">
            <li>
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl1', 1)"
                    @click="clickTooltip(getAddress('lvl1', 1))"
                >
                    <!-- First line top -->
                    <UiAvatar v-if="blockchain?.lvl1[1]" :user="blockchain?.lvl1[1]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>
                <!-- Small purple circle-->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl1[1]" /> -->
            </li>
            <li>
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl1', 0)"
                    @click="clickTooltip(getAddress('lvl1', 0))"
                >
                    <!-- First line bottom -->
                    <UiAvatar v-if="blockchain?.lvl1[0]" :user="blockchain?.lvl1[0]" />

                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>
                <!-- Small purple circle-->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl1[0]" /> -->
            </li>
            <!-- Main User. Center of circle  -->
            <li class="main__img">
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="`${user.name} ${user.account}`"
                    @click="clickTooltip(`${user.name} ${user.account}`)"
                >
                    <!-- <img
                    src="/assets/dashboard/matrix-level-tactile/not-for-prod/user.jpg"
                /> -->
                    <UiAvatar :user="user" />
                </p>
            </li>
        </ul>
    </div>
    <div class="classic__level-map circles__sm" v-else>
        <ul class="circle__container circle__3">
            <li>
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl1', 0)"
                    @click="clickTooltip(getAddress('lvl1', 0))"
                >
                    <!-- Top right img -->
                    <UiAvatar v-if="blockchain?.lvl1[0]" :user="blockchain?.lvl1[0]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>

                <!-- Popup user info  -->

                <!-- Small circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl1[0]" /> -->
            </li>
            <li>
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl1', 1)"
                    @click="clickTooltip(getAddress('lvl1', 1))"
                >
                    <!-- Bottom right img -->
                    <UiAvatar v-if="blockchain?.lvl1[1]" :user="blockchain?.lvl1[1]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>

                <!-- Popup user info  -->

                <!-- Small circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl1[1]" /> -->
            </li>
            <li>
                <!-- Bottom left img -->
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="tooltipContent('lvl1', 2)"
                    @click="clickTooltip(getAddress('lvl1', 2))"
                >
                    <UiAvatar v-if="blockchain?.lvl1[2]" :user="blockchain?.lvl1[2]" />
                    <svg style="width: 24px; height: 24px" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 0L12 24M0 12L24 12" stroke="#666666" stroke-width="2" />
                    </svg>
                </p>

                <!-- Popup user info  -->
                <!-- Small circle -->
                <!-- <SmallPurpleSvg v-if="blockchain?.lvl1[2]" /> -->
            </li>
            <li class="main__img">
                <p
                    v-tippy="{ maxWidth: 380 }"
                    :content="`${user.name} ${user.account}`"
                    @click="clickTooltip(`${user.name} ${user.account}`)"
                >
                    <!-- Center user's img -->
                    <UiAvatar :user="user" />
                </p>
            </li>
        </ul>
    </div>
</template>
<script>
import { copyToClipboard } from "@/helpers/clipboard"
import { mapGetters } from "vuex"
// import SmallPurpleSvg from "./SmallPurpleSvg.vue"
export default {
    props: {
        user: Object,
        blockchain: Object,
        cardType: String
    },

    // components: {
    //     SmallPurpleSvg,
    // },
    computed: {
        ...mapGetters("ui", ["isSideBarClose"]),
    },

    methods: {
        tooltilBlock(lvl, index) {
            if (!this.blockchain || !this.blockchain[lvl]) return null
            const target = this.blockchain[lvl][index]
            const date = new Date(Date.parse(target['created_at']))
            const formatDate = `${date.getDay() + ' ' + date.getMonth() + ' ' + date.getFullYear()}`
            return `
                <div class="user__info">
                    <div class="user__info-title">
                        <div class="user__info-id">
                            ${target.name}
                            <span 
                                style="
                                    background: var(--color-purple);
                                    'border-radius': 10px;
                                    padding: 0 2px"
                            >new</span>
                        </div>
                        <div class="user__info-date">${formatDate}</div>
                    </div>

                    <div class="user__info-status" style="color: var(--color-orange)">
                        <svg style="margin-right: 4px; margin-top: -1px" width="12" height="12" viewBox="0 0 12 12"
                            fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="12" height="12" rx="6" fill="#F8956C" />
                        </svg>

                        Overtaking
                    </div>
                    <div class="user__info-description">
                        description about, Small description about overtaking,
                    </div>
                </div>
            `
        },
        tooltipContent(lvl, index) {
            return this.getAddress(lvl, index) || this.$t("matrix.tooltip_empty_state")
        },
        getAddress(lvl, index, isPure) {
            if (!this.blockchain || !this.blockchain[lvl]) return null

            const target = this.blockchain[lvl][index]

            if (target && typeof target === "object") {
                return isPure ? target : `${target.name} ${target.account}`
            } else if (typeof target === "string") {
                return target
            }

            return null
        },

        clickTooltip(text) {
            if (!text) return
            let address = text.split(" ")[1] || text
            copyToClipboard(address)
                .then(() => {
                    this.$toast.success(`${address} ${this.$t("matrix.copied")}`)
                })
                .catch(() => {
                    this.$toast.error(`${this.$t("matrix.copyError")}`)
                })
        },
    },
}
</script>
<style lang="scss">
g, p {
    &:focus {
        outline: none;
    }
}
</style>