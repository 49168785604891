<template>
  <div class="classic__wrap">
    <div class="classic__main-content">
      <div class="classic__level">
        <div class="classic__level-title">
          <div class="classic__level-title_left">
            <p class="name">
              <span>{{ $t('matrix.level') }} {{ level + 1 }}</span>
              {{ price }} DAI
            </p>
            <p class="progress">{{ progressbar }} {{ $t('matrix.completed') }}</p>
          </div>
          <div class="classic__level-title_right hidden-xs">
            <p v-if="blockchain?.pureRevenueCycle">
              <span>{{ $t("matrix.stats.pureRevenue") }}</span>
              {{ blockchain?.pureRevenueCycle }} DAI
            </p>
            <p v-if="blockchain?.totalSpend">
              <span>{{ $t("matrix.stats.revenue") }}</span>
              {{ blockchain?.totalSpend }} DAI
            </p>
          </div>
          <div class="progressbar" :style="{ width: progressbar }"></div>
        </div>
        <div id="svg-tooltip" />
        <RenderCardLevel v-if="blockchain && cardType && !loading" :user="user" :blockchain="blockchain"
          :cardType="cardType" />
        <UiLoader v-if="loading" theme="block" :loading="true" />
        <div v-if="blockchain && isMyAccount" class="classic__level__withdrawBlock">
          <div class="classic__level__withdrawBlock-balance">
            <UiLoader v-if="withdrawLoading" theme="block" :loading="true" />
            <template v-else>
              <span class="top">{{ $t("matrix.frozenBalance") }} : </span>
              <span class="value-bottom">{{ blockchain.totalFrozen }} DAI</span>
            </template>
          </div>
          <UiButton v-if="this.levelInfo.active === 'gap'" class="activateLevelTactile" :text="$t('matrix.activateLevel')"
            @click="handleActivationClick" />
          <div v-if="blockchain.totalFrozen !== '0'" class="classic__level__withdrawBlock__buttons">
            <button @click="handleWithdrawClick">{{ withdrawText }}</button>
          </div>
        </div>
        <RenderPagination v-show="!shortPagination" :meta="paginationMeta" @onChange="handlePaginationSelect"
          @switchPaginationToShort="shortPagination = true" />

        <div class="circles__buttons" v-show="shortPagination">
          <div class="circles__btn" @click.stop="openPagination">
            {{ paginationMeta.current }} <span class="circles__caption">{{ $t('matrix.stats.cycles') }}</span>
            <BaseIcon name="pagArrowRight" verAlign />
          </div>
          <div class="switch__wrap" v-if="blockchain && isMyAccount">
            <div class="switch__btn switch__item">
              <input id="autoUpgrade" type="checkbox" name="autoUpgrade" v-model="autoUpgradeValue" hidden />
              <label class="switch__label" for="autoUpgrade" @click.prevent.stop="handleUpgradeChange">
                {{ $t("matrix.autoUpgrade") }}
                <span></span>
              </label>
            </div>
            <div class="switch__btn switch__item">
              <input id="reBuy" type="checkbox" name="reBuy" v-model="autoRecycleValue" hidden />
              <label class="switch__label" for="reBuy" @click.prevent.stop="handleRecycleChange">
                {{ $t("matrix.reActivation") }}
                <span></span>
              </label>
            </div>
            <a href="/assets/files/pdf/Tactile_en.pdf" target="_blank" class="switch__info switch__item">
              <span class="switch__info-caption">{{ $t('matrix.stats.moreInfo') }}</span>
              <BaseIcon name="moreInfo" verAlign />
            </a>
          </div>
        </div>

        <div class="profit-mobile profit-mobile--hide">
          <p class="profit-mobile__item">
            <span>{{ $t("matrix.stats.pureRevenue") }}</span>
            <span class="profit-mobile__amount>" v-if="blockchain?.pureRevenueCycle">{{ blockchain?.pureRevenueCycle }}
              DAI</span>
          </p>
          <p class="profit-mobile__item">
            <span>{{ $t("matrix.stats.revenue") }}</span>
            <span class="profit-mobile__amount>" v-if="blockchain?.totalSpend">{{ blockchain?.totalSpend }} DAI</span>
          </p>
        </div>
      </div>
    </div>
    <TactileRecentActivity />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { getClassicTypeByLevel, getClassicPriceByLevel } from '@/helpers/matrix'
import { isSameAccount } from '@/helpers/user'
import { Log } from '@/helpers/dev'
import RenderCardLevel from './Render/RenderCardLevel.vue'
import RenderPagination from './Render/RenderPagination.vue'
import TactileRecentActivity from '@/components/Matrix/TactileRecentActivity.vue'
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  props: {
    lvl: [Number, String],
    user: Object
  },
  components: {
    RenderCardLevel,
    RenderPagination,
    BaseIcon,
    TactileRecentActivity,
  },
  data() {
    return {
      activeSlot: 0,
      blockchain: null,
      loading: true,
      withdrawLoading: false,
      withdrawProcessing: false,
      autoUpgradeInner: null,
      autoRecycleInner: null,
      recyclePending: false,
      autoupgradePending: false,
      levels: [],
      shortPagination: true,
      progressbar: ''
    }
  },
  async mounted() {
    if (!this.lvl) this.$router.push({ name: 'classicAllLevels' })
    localStorage.setItem('lvl', this.lvl)
    const [err, levels] = await this.getProgramLevels(this.user.account)
    this.levels = levels
    await this.getStructure({ type: 'initial' })
    this.progressPercent()
    this.progressbar = localStorage.getItem('progressBar')
  },

  computed: {
    isMyAccount() {
      return isSameAccount(this.getAccount, this.user.account)
    },
    level() {
      if (this.lvl) {
        return Number(this.lvl) - 1
      } else {
        const lvl = localStorage.getItem('lvl')
        return Number(lvl) - 1
      }
    },
    cardType() {
      return getClassicTypeByLevel(this.level)
    },
    price() {
      return getClassicPriceByLevel(this.level)
    },
    totalSlots() {
      if (!this.blockchain) return 1

      return Number(this.blockchain?.totalSlots) + 1
    },
    prevLevelAvailable() {
      try {
        return this.levels[this.level - 1].active
      } catch {
        return false
      }
    },
    nextLevelAvailable() {
      try {
        return this.levels[this.level + 1].active
      } catch {
        return false
      }
    },
    levelInfo() {
      return this.levels[this.level]
    },
    paginationMeta() {
      return {
        total: this.totalSlots,
        current: this.activeSlot + 1
      }
    },
    autoRecycleValue() {
      return this.autoRecycleInner !== null
        ? this.autoRecycleInner
        : this.blockchain.autoRecycle
    },
    autoUpgradeValue() {
      return this.autoUpgradeInner !== null
        ? this.autoUpgradeInner
        : this.blockchain.autoUpgrade
    },
    isWithdrawFree() {
      return this.nextLevelAvailable || this.level === 11
    },
    withdrawText() {
      if (this.isWithdrawFree) {
        return this.$t('matrix.withdraw')
      } else {
        return this.$t('matrix.withdrawWithCommission')
      }
    },
    ...mapGetters('crypto', ['getAccount'])
  },
  methods: {
    progressPercent() {
      if (this.blockchain) {
        const { slot, totalPartners } = this.blockchain
        if (totalPartners) {
          const totalInSlot = this.cardType === 's6' ? 6 : 3
          const activeInSlot =
            Number(slot) !== 0
              ? totalPartners - totalInSlot * Number(slot)
              : totalPartners

          const calc = Math.round((activeInSlot / totalInSlot) * 100)
          if (!localStorage.getItem('progressBar')) {
            localStorage.setItem('progressBar', `${calc}%`)
          }
        }
      }
      if (!localStorage.getItem('progressBar')) {
        localStorage.setItem('progressBar', '0%')
      }
    },
    openPagination() {
      this.shortPagination = false
    },
    async swalConstructor(text) {
      const res = await this.$swal
        .fire({
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          text: this.$t(text)
        })
        .then(async result => {
          if (result.isConfirmed) {
            return true
          }
        })

      return res
    },
    async processWithdraw() {
      const [err, res] = await this.withdrawFrozen({
        type: this.cardType,
        lvl: this.level.toString(),
        onBlockchainPending: () => {
          this.withdrawProcessing = true
        }
      })

      if (err) {
        this.$swal(err.message)
      }
    },
    async handleWithdrawClick(e) {
      if (this.withdrawLoading) return
      this.withdrawLoading = true

      if (!this.isWithdrawFree) {
        const modalRes = await this.swalConstructor(
          'matrix.activateCommisionText'
        )

        if (modalRes) {
          await this.processWithdraw()
        }
      } else {
        await this.processWithdraw()
      }

      // this.getStructure({ type: "refresh" })

      this.withdrawLoading = false
      this.withdrawProcessing = false
    },
    async handleRecycleChange(e) {
      let agreed = true

      if (!this.autoRecycleValue === false) {
        agreed = await this.swalConstructor('matrix.recycleCommisionText')
        if (!agreed) return
      }

      this.recyclePending = true

      const [err, res] = await this.changeAutoReCycle({
        flag: !this.autoRecycleValue,
        lvl: this.level.toString()
      })

      this.autoRecycleInner = err ? this.autoRecycleValue : !this.autoRecycleValue

      this.recyclePending = false
    },
    async handleUpgradeChange(e) {
      this.autoupgradePending = true

      const [err, res] = await this.changeAutoUpgrade({
        flag: !this.autoUpgradeValue,
        lvl: this.level.toString()
      })

      this.autoUpgradeInner = err ? this.autoUpgradeValue : !this.autoUpgradeValue

      this.autoupgradePending = false
    },
    async getStructure({ type, slot = 0 }) {
      this.loading = true

      const [err, res] = await this.requestStructure({
        slot,
        account: this.user.account,
        level: this.level,
        type: this.cardType,
        fetchUser: true,
        countRevenue: this.blockchain && this.blockchain.pureRevenue
      })

      if (err) {
        this.$toast.error(
          `Fetch structure for level: ${this.card.lvl} from blockchain error: ${err}`
        )
        this.$router.push({ name: 'classicAllLevels' })
      }

      Log(`structure responce for slot - ${slot}`, { res })
      this.blockchain = res ? { ...res } : null

      if (type === 'initial' && +res?.totalSlots >= 1) {
        await this.handlePaginationSelect(+res?.totalSlots + 1)
      }

      this.loading = false
    },
    async handlePaginationSelect(slot) {
      this.loading = true

      this.activeSlot = slot - 1
      await this.getStructure({ type: "nav", slot: slot - 1 })

      this.loading = false
    },
    async handleActivationClick() {
      let agreed = true

      if (!this.autoRecycleValue) {
        agreed = await this.swalConstructor('matrix.recyclePurchaseText')

        if (!agreed) return

        await this.handleRecycleChange()
      }

      if (this.autoRecycleValue) {
        this.setActivationModal({
          visible: true,
          type: this.cardType,
          level: this.level,
          cost: this.price
        })
      }
    },
    ...mapActions('crypto', [
      'requestStructure',
      'getProgramLevels',
      'changeAutoReCycle',
      'changeAutoUpgrade',
      'withdrawFrozen'
    ]),
    ...mapMutations('ui', ['setActivationModal'])
  },
  watch: {
    lvl(newLvl) {
      this.loading = true

      this.activeSlot = 0
      this.getStructure({ type: 'initial' })

      this.autoUpgradeInner = null
      this.autoRecycleInner = null

      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.activateLevelTactile {
  color: var(--color-white-30);
  font-weight: 400;
  font-size: var(--indent-14);
  transition: .4s;

  &:hover {
    background: transparent;
    color: var(--color-black-50);
  }
}
</style>
